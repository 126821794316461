import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
const Oferta = () => {
  useEffect(() => {
    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    // Викликайте scrollToTop при завантаженні сторінки
    scrollToTop();
  }, []);

  return (
    <>
      <strong className="Oferta">
        ; ПУБЛІЧНИЙ ДОГОВІР (ОФЕРТА)
        <br />
        на замовлення, купівлю-продаж і доставку товарів
        <br />
        <br />
        Цей договір є офіційною та публічною пропозицією Продавця укласти
        договір купівлі-продажу Товару, представленого на сайті
        https://mister-gifter.com/. Даний договір є публічним, тобто відповідно
        до статті 633 Цивільного кодексу України, його умови є однаковими для
        всіх покупців незалежно від їх статусу (фізична особа, юридична особа,
        фізична особа-підприємець) без надання переваги одному покупцю перед
        іншим. Шляхом укладення цього Договору покупець в повному обсязі приймає
        умови та порядок оформлення замовлення, оплати товару, доставки товару,
        повернення товару, відповідальності за недобросовісне замовлення та усі
        інші умови договору. Договір вважається укладеним з моменту натискання
        кнопки «Підтвердити Замовлення» на сторінці оформлення замовлення в
        Розділі «Кошик» і отримання Покупцем від Продавця підтвердження
        замовлення в електронному вигляді.
        <br />
        <br />
        1. Визначення термінів
        <br />
        1.1. Публічна оферта (далі - «Оферта») - публічна пропозиція Продавця,
        адресована невизначеному колу осіб, укласти з Продавцем договір
        купівлі-продажу товару дистанційним способом (далі - «Договір») на
        умовах, що містяться в цій Оферті.
        <br />
        1.2. Товар або Послуга – об'єкт угоди сторін, який був обраний покупцем
        на сайті Інтернет-магазину та поміщений у кошик, або вже придбаний
        Покупцем у Продавця дистанційним способом.
        <br />
        1.3. Інтернет-магазин – сайт Продавця за адресою
        https://mister-gifter.com/ створений для укладення договорів роздрібної
        та оптової купівлі-продажу на підставі ознайомлення Покупця із
        запропонованим Продавцем описом Товару за допомогою мережі Інтернет.
        <br />
        1.4. Покупець – дієздатна фізична особа, яка досягла 18 років, отримує
        інформацію від Продавця, розміщує замовлення щодо купівлі товару, що
        представлений на сайті Інтернет-магазину для цілей, що не пов'язані зі
        здійсненням підприємницької діяльності, або юридична особа чи фізична
        особа-підприємець.
        <br />
        1.5. Продавець – «Mister Gifter» , ФОП, яка створена і діє відповідно до
        чинного законодавства України.
        <br />
        <br />
        2. Предмет Договору
        <br />
        2.1. Продавець зобов’язується передати у власність Покупцю Товар, а
        Покупець зобов’язується оплатити і прийняти Товар на умовах цього
        Договору.
        <br />
        2.2. Датою укладення Договору-оферти (акцептом оферти) та моментом
        повного й беззаперечного прийняттям Покупцем умов Договору вважається
        дата заповнення Покупцем форми замовлення, розташованої на сайті
        Інтернет-магазину, за умови отримання Покупцем від Продавця
        підтвердження замовлення в електронному вигляді. У разі необхідності, за
        бажанням Покупця, Договір може бути оформлений у письмовій формі.
        <br />
        <br />
        3. Оформлення Замовлення
        <br />
        <br />
        3.1. Покупець самостійно оформлює замовлення в Інтернет-магазину через
        форму «Кошика», або зробивши замовлення електронною поштою чи за номером
        телефону, вказаним в розділі контактів Інтернет-магазину.
        <br />
        3.2. Продавець має право відмовитися від передання замовлення Покупцеві
        у випадку, якщо відомості, вказані Покупцем під час оформлення
        замовлення, є неповними або викликають підозру щодо їх дійсності.
        <br />
        3.3. При оформленні замовлення на сайті Інтернет-магазину Покупець
        зобов'язується надати наступну обов’язкову інформацію, необхідну
        Продавцю для виконання замовлення:
        <br />
        3.3.1. прізвище, ім'я Покупця;
        <br />
        3.3.2. адреса, за якою слід доставити Товар (якщо доставка до адреси
        Покупця);
        <br />
        3.3.3. контактний телефон.
        <br />
        3.3.4. Ідентифікаційний код для юридичної особи або фізичної-особи
        підприємця.
        <br />
        3.4. Найменування, кількість, артикул, ціна обраного Покупцем Товару
        вказуються в кошику Покупця на сайті Інтернет-магазину.
        <br />
        3.5. Якщо будь-якої із Сторін договору необхідна додаткова інформація,
        він має право запросити її у іншій Стороні. У разі ненадання необхідної
        інформації Покупцем, Продавець не несе відповідальності за надання
        якісної послуги Покупцю при покупці товару в інтернет-магазині.
        <br />
        3.6. При оформленні замовлення через оператора Продавця (п. 3.1. Цієї
        Оферти) Покупець зобов'язується надати інформацію, зазначену в п. 3.3 –
        3.4. цієї Оферти.
        <br />
        3.7. Ухвалення Покупцем умов цієї Оферти здійснюється за допомогою
        внесення Покупцем відповідних даних в реєстраційну форму на сайті
        Інтернет-магазину або при оформленні Замовлення через оператора. Після
        оформлення Замовлення через Оператора дані про Покупця вносяться до бази
        даних Продавця.
        <br />
        3.8. Покупець несе відповідальність за достовірність наданої інформації
        при оформленні Замовлення.
        <br />
        3.9. Укладаючи Договір, тобто акцентуючи умови даної пропозиції
        (запропоновані умови придбання Товару), шляхом оформлення Замовлення,
        Покупець підтверджує наступне:
        <br />
        а) Покупець цілком і повністю ознайомлений, і згоден з умовами цієї
        пропозиції (оферти);
        <br />
        б) він дає дозвіл на збір, обробку та передачу персональних даних,
        дозвіл на обробку персональних даних діє протягом усього терміну дії
        Договору, а також протягом необмеженого терміну після закінчення його
        дії. Крім цього, укладенням договору Покупець підтверджує, що він
        повідомлений (без додаткового повідомлення) про права, встановлених
        Законом України "Про захист персональних даних", про цілі збору даних, а
        також про те, що його персональні дані передаються Продавцю з метою
        можливості виконання умов цього Договору, можливості проведення
        взаєморозрахунків, а також для отримання рахунків, актів та інших
        документів. Покупець також погоджується з тим, що Продавець має право
        надавати доступ та передавати його персональні дані третім особам без
        будь-яких додаткових повідомлень Покупця з метою виконання замовлення
        Покупця. Обсяг прав Покупця, як суб'єкта персональних даних відповідно
        до Закону України "Про захист персональних даних" йому відомий і
        зрозумілий.
        <br />
        <br />
        <br />
        4. Ціна і Доставка Товару
        <br />
        4.1 Ціни на Товари та послуги визначаються Продавцем самостійно та
        вказані на сайті Інтернет-магазину. Всі ціни на Товари та послуги
        вказані на сайті у гривнях з урахуванням ПДВ.
        <br />
        4.2 Ціни на Товари та послуги можуть змінюватися Продавцем в
        односторонньому порядку залежно від кон'юнктури ринку. При цьому ціна
        окремої одиниці Товару, вартість якої оплачена Покупцем в повному
        обсязі, не може бути змінена Продавцем в односторонньому порядку.
        <br />
        4.3. Вартість Товару, яка вказана на сайті Інтернет-магазину не включає
        в себе вартість доставки Товару Покупцю. Вартість доставки Товару
        Покупець сплачує відповідно до діючих тарифів служб доставки
        (перевізників) безпосередньо обраній ним службі доставки (перевізнику).
        <br />
        4.4. Вартість Товару яка вказана на сайті Інтернет-магазину не включає в
        себе вартість доставки Товару на адресу Покупця.
        <br />
        4.5. Продавець може вказати орієнтовну вартість доставки Товару на
        адресу Покупця під час звернення Покупця із відповідним запитом до
        Продавця шляхом надіслання листа на електронну пошту або при оформленні
        замовлення через оператора інтернет-магазину.
        <br />
        4.6. Зобов'язання Покупця по оплаті Товару вважаються виконаними з
        моменту надходження Продавцю коштів на його рахунок.
        <br />
        4.7. Розрахунки між Продавцем і Покупцем за Товар здійснюються
        способами, зазначеними на сайті Інтернет-магазину в розділі «Оплата і
        Доставка».
        <br />
        4.8. При отриманні товару Покупець повинен у присутності представника
        служби доставки (перевізника) перевірити відповідність Товару якісним і
        кількісним характеристикам (найменування товару, кількість,
        комплектність, термін придатності).
        <br />
        4.9. Покупець або його представник під час приймання Товару підтверджує
        своїм підписом в товарному чеку/ або в замовленні/ або в транспортній
        накладній на доставку товарів, що не має претензій до кількості товару,
        зовнішнім виглядом і комплектності товару.
        <br />
        4.10. Право власності та ризик випадкової втрати або пошкодження Товару
        переходить до Покупця або його Представника з моменту отримання Товару
        Покупцем в місті поставки Товару при самостійній доставки Товару від
        Продавця, чи під час передачі Продавцем товару службі доставки
        (перевізнику) обраної Покупцем.
        <br />
        <br />
        5. Права ті обов’язки Сторін
        <br />
        5.1. Продавець зобов’язаний:
        <br />
        5.1.1. Передати Покупцеві товар у відповідності до умов цього Договору
        та замовлення Покупця.
        <br />
        5.1.2. Не розголошувати будь-яку приватну інформацію про Покупця і не
        надавати доступ до цієї інформації третім особам, за винятком випадків,
        передбачених законодавством та під час виконання
        <br />
      </strong>
      <Helmet>
        <title>Оферта</title>
      </Helmet>
    </>
  );
};
export default Oferta;
